<template>
  <div>
    <trac-loading v-if="loading" />
    <div class="flex justify-between">
      <h2 class="text-xl font-bold text-gray-800">Tags</h2>
      <CreateTag
        :loading="loading"
        :show="showModal"
        :tag-text="tagTextHolder"
        :tag-id="tagIdHolder"
        :tag-success-text="tagSuccessText"
        :show-success="addTagSuccessModal"
        @toggle-show="showModal = !showModal"
        @toggle-success="toggleAddTagSuccessModal"
        @update-tag="updateTag"
        @add-tag="addTag"
      >
        <trac-button
          @button-clicked="showModal = true"
          class="flex-initial uppercase"
          id="create-tag"
          >Create New Tag
        </trac-button>
      </CreateTag>
    </div>
    <div class="bg-white mt-12 px-6 py-6 big-shadow">
      <!-- 
        Create a text field for searching tags
       -->
      <div class="flex mb-6 justify-between items-center">
        <div class="w-1/2">
          <div
            class="flex flex-row bg-white items-center rounded justify-between"
          >
            <img src="../../assets/svg/Search_icon.svg" class="mr-3" />
            <span class="text-xs font-light text-primaryGray w-full">
              <input
                class="w-full py-2 outline-none font-bold"
                type="text"
                name=""
                id=""
                v-model="search"
                placeholder="Search tag..."
              />
            </span>
          </div>
        </div>
      </div>
      <table class="w-full table-auto">
        <thead class="bg-blue-100">
          <tr>
            <th
              class="text-left w-1/6 whitespace-no-wrap p-4 text-xs font-semibold"
            >
              No.
            </th>
            <th
              class="text-left w-4/6 whitespace-no-wrap p-4 text-xs font-semibold"
            >
              Name
            </th>
            <th
              class="text-left w-1/6 whitespace-no-wrap p-4 text-xs font-semibold"
            >
              Actions
            </th>
          </tr>
        </thead>
        <tbody v-if="tags.length <= 0">
          <tr>
            <td colspan="7">
              <div
                class="flex justify-center items-center w-full h-full bg-transparent"
              >
                <div class="h-40 flex items-center text-lg text-gray-600">
                  No product added yet.
                </div>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr
            class="cursor-pointer hover:bg-gray-200"
            v-for="(tag, index) in paginatedList"
            :key="tag._id"
          >
            <td class="text-xs whitespace-no-wrap p-4">
              {{ page * limit - limit + (index + 1) }}
            </td>
            <td class="text-xs whitespace-no-wrap p-4 font-bold capitalize">
              {{ tag.tagName }}
            </td>
            <td class="text-xs whitespace-no-wrap p-4 font-bold capitalize">
              <!-- <button @click="editTag(tag.tagName)" class="text-white bg-blue-500 rounded-md px-4 py-2">
                        Edit
                    </button>
                    <button @click="deleteTag(tag._id)" class="text-white bg-red-500 rounded-md px-4 py-2">
                        Delete
                    </button> -->

              <!-- Create a dropdown with buttons for 'edit' and 'delete' using tailwind. Do NOT use x-show or x-data -->
              <div class="relative" v-click-outside="reset">
                <button
                  @click.stop
                  @click="currentTagIndex = index"
                  class="border smallest text-primaryBlue focus:outline-none border-primaryBlue py-2 px-4 rounded inline-flex justify-between capitalize items-center cursor-pointer w-full mb-2"
                >
                  Actions
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    class="fill-current h-4 w-4"
                  >
                    <path
                      d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                    ></path>
                  </svg>
                </button>
                <div
                  v-if="currentTagIndex === index"
                  class="absolute right-0 w-32 bg-white rounded-md shadow-xl z-20"
                >
                  <button
                    @click="editTag(tag.tagName, tag._id)"
                    class="block w-full text-left px-4 py-2 text-xs text-gray-700 hover:bg-gray-500 hover:text-white"
                  >
                    Edit
                  </button>
                  <button
                    @click="deleteTag(tag._id)"
                    class="block w-full text-left px-4 py-2 text-xs text-gray-700 hover:bg-gray-500 hover:text-white"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-if="!loading" class="flex justify-center mt-4">
        <trac-button
          :disabled="paginatedList.length >= tags.length"
          @button-clicked="loadMoreTags"
        >
          {{
            paginatedList.length >= tags.length ? "No More Items" : "Load More"
          }}
        </trac-button>
      </div>
    </div>
  </div>
</template>

<script>
import tagMixin from "./Tags.mixin";
import CreateTag from "./CreateTag.vue";
import Pagination from "vue-pagination-2";

export default {
  mixins: [tagMixin],
  components: {
    CreateTag,
    Pagination,
  },
  data() {
    return {
      limit: 20,
      page: 1,
    };
  },
  computed: {
    paginatedList() {
      const from = (this.page - 1) * this.limit;
      const to = from + this.limit;
      return this.filteredList?.slice(from, to);
    },
  },
  methods: {
    loadMoreTags() {
      this.page = 1;
      this.limit = this.limit + 10;
    },
  },
};
</script>

<style lang="postcss" scoped></style>
